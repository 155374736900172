import * as consts from "../constants/userConstants";
import { addAlertMessage } from "./systemActions";
import axiosNT from "../axiosInstances/axiosNoToken";
import Cookies from "js-cookie";

export const login = (username, password) => async (dispatch) => {
  try {
    dispatch({
      type: consts.USER_LOGIN_REQUEST,
    });

    const { data } = await axiosNT.post("users/login", {
      username: username,
      password: password,
    });

    dispatch({
      type: consts.USER_LOGIN_SUCCESS,
      payload: data,
    });

    Cookies.set("userInfo", JSON.stringify(data), {
      // httpOnly: true, this wont let you access the token or any other else (hackers)
      secure: true, // Only send over HTTPS connections
      expires: 1, // Expire after 1 days
      sameSite: "strict" /* Restrict cross-site requests */,
    });
  } catch (error) {
    const message = error.response.data.message
      ? error.response.data.message
      : error.message;
    error.response
      ? error.response.status === 401 &&
        dispatch(addAlertMessage(message, "error"))
      : error instanceof Error &&
        dispatch(
          addAlertMessage(
            "خطأ في الشبكة يرجى التأكد من إتصالك بالإنترنت",
            "error"
          )
        );

    dispatch({
      type: consts.USER_LOGIN_FAIL,
      payload: error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};

export const logout = () => async (dispatch) => {
  Cookies.remove("userInfo");
  dispatch({
    type: consts.USER_LOGOUT,
  });
};
