import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import mainRoutes from "./routes/mainRoutes";

function App() {
  const { loginInfo } = useSelector((state) => state.userLogin);

  // set the app routes
  const routes = mainRoutes;

  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route, index) => {
          if ((route.isPrivate === true) & (loginInfo === null))
            return (
              <Route
                key={index}
                path={route.path}
                element={<Navigate to={"/login"} />}
              />
            );
          else if ((route.mustLogout === true) & (loginInfo !== null))
            return (
              <Route
                key={index}
                path={route.path}
                element={<Navigate to={"/"} />}
              />
            );
          return (
            <Route key={index} path={route.path} element={route.element} />
          );
        })}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
