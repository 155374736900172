import { configureStore, combineReducers } from "@reduxjs/toolkit";
import thunk from "redux-thunk";

import { userLoginReducer } from "./reducers/userReducers";

import Cookies from "js-cookie";

import { alertMessagesReducer } from "./reducers/systemReducer";

const rootReducer = combineReducers({
  userLogin: userLoginReducer,
  alertMessages: alertMessagesReducer,
});

// Check if in development mode
const isDevelopment = process.env.NODE_ENV === "development";

const middleware = [thunk];

const userInfoFromCookie = Cookies.get("userInfo")
  ? JSON.parse(Cookies.get("userInfo"))
  : null;

const initialState = {
  userLogin: {
    loadingLogin: false,
    msgLogin: null,
    loginInfo: userInfoFromCookie,
  },
  alertMessages: {
    messages: [],
  },
};

const store = configureStore({
  reducer: rootReducer,
  middleware: [...middleware],
  preloadedState: initialState,
  devTools: isDevelopment ? true : false,
});

export default store;
