import axios from "axios";
// "http://api.tipmarathon.ly/"
const axiosNT = axios.create({
  baseURL: "https://api.tipmarathon.ly/",
  timeout: 10000,
  headers: {
    "Content-type": "application/json",
  },
});

/*  in case stopped this code the axios instance by default make network error
 but using this code you can handle custom network error message without the need to write
 the error message in each time you use this axios instance */

axiosNT.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      throw new Error("خطأ في الشبكة يرجى التأكد من إتصالك بالإنترنت");
    }
    return Promise.reject(error);
  }
);

export default axiosNT;
