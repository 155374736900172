import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import { Box, Button, Tab, TextField } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import axiosNT from "../redux/axiosInstances/axiosNoToken";
import { addAlertMessage } from "../redux/actions/systemActions";
import { useDispatch } from "react-redux";
import AlertMessage from "../components/AlertMessage";

const MainPage = () => {
  const dispatch = useDispatch();

  const [value, setValue] = useState("1");
  const [searchValue, setSearchValue] = useState("");
  const [result, setResult] = useState(false);
  const [resultMessage, setResultMessage] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const checkRegistration = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosNT.get("check-registration", {
        params: { search_value: searchValue },
      });

      response.data.exist === true
        ? dispatch(addAlertMessage(response.data.detail))
        : dispatch(addAlertMessage(response.data.detail, "warning"));
    } catch (error) {
      dispatch(addAlertMessage(error.response.data.detail, "error"));
    }
  };

  return (
    <div className="main-page">
      <Carousel>
        <Carousel.Item>
          <img
            src={`${process.env.PUBLIC_URL}/images/marathon.jpeg`}
            style={{ width: "auto", height: "300px" }}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            src={`${process.env.PUBLIC_URL}/images/marathon.jpeg`}
            style={{ width: "auto", height: "300px" }}
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            src={`${process.env.PUBLIC_URL}/images/marathon.jpeg`}
            style={{ width: "auto", height: "300px" }}
          />
        </Carousel.Item>
      </Carousel>

      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="التسجيل" value="1" />
              <Tab label="الإستعلام" value="2" />
              <Tab label="شروط المسابقة" value="3" />
              <Tab label="عن المسابقة" value="4" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <h3>
              سجل اسمك ضمن قوائم المشاركين بالماراثون من{" "}
              <Link to={"/registration"}>
                <span>هنا</span>
              </Link>
            </h3>
          </TabPanel>
          <TabPanel value="2">
            <h3>يمكنك التأكد إذا تم تسجيلك</h3>
            <br />
            <form
              onSubmit={checkRegistration}
              className="d-flex flex-row justify-content-ceter align-items-center gap-4"
            >
              <TextField
                required
                variant="standard"
                label="البحث عن مشترك"
                placeholder="الإسم الثلاثي أو رقم الهاتف"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                autoComplete="off"
              />
              <Button type="submit" variant="contained">
                تحقق
              </Button>
            </form>
          </TabPanel>
          <TabPanel value="3">
            المشاركة مفتوحة لكل المدن الليبية ومن خارج ليبيا تقتصر المشاركة على
            فئة الذكور فقط يتم إرسال المعلومات الشخصية كاملة عن طريق منظومة
            التسجيل مرفقة برقم الهاتف ويفضل أن يكون به واتس أب للتواصل التعهد من
            المتسابق بأنه لايعاني من أي مشاكل صحية ألا يقل عمر المشارك عن 15
            السنة الحضور لموقع السباق قبل ساعة ونصف من موعد الإنطلاق التقيد
            بتعليمات اللجنة الفنية المشرفة على السباق يمنع على المتسابق تغيير
            مسار السباق المحدد من قبل اللجنة المنظمة إلغاء المشاركة في حال ثبوت
            إستعانة المتسابق بأي وسيلة نقل التأكد من تثبيت رقم الصدرية على الجهة
            الأمامية للغلالة وإبرازه في محطات التسجيل
          </TabPanel>
          <TabPanel value="4">
            بدأ نصف ماراثون طرابلس رحلته من سنة 2016 واستمر بطبعاته المتتالية
            وبزخم يكبر ويزداد مع كل عام يقام فيه ، حيث وصف بأنه حدث بمعايير
            دولية وذلك بحجم المشاركات وألية تنظيمه يعتبر نصف ماراثون طرابلس
            إختبار تحمل في رياضة ألعاب القوى وتبلغ مسافته 21 كلم أو مايعادل نصف
            الماراثون ويقام في أهم شوارع العاصمة طرابلس نقطة الإنطلاقة من أمام
            ميدان الفروسية ( أبي ستة ) بجانب شركة ليبيانا والإتجاه شرقا إلى
            جزيرة معيتيقه والعودة غربا بإتجاه برج أبوليلة ومن ثم العودة مرة أخرى
            إلى نقطة الإنطلاق إلى ميدان الفروسية منح التراتيب الأولى في الترتيب
            العام الأول والثاني والثالث كأس وميدالية يوم السباق وجوائز قيمة يوم
            حفل توزيع الجوائز .. - - يتم تصنيف الفئات العمرية بالسباق وتمنح
            جوائز للفائزين بالتراتيب الأولى لكل فئة وسنعلن لاحقا عن العدد وموعد
            تسليم الجوائز
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};

export default MainPage;
