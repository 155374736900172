// src/theme.js (or any suitable filename)
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  direction: "rtl",

  palette: {
    type: "light", // Set the desired palette type (light or dark)
    primary: {
      main: "#009688", // Set your desired primary color
    },
    secondary: { main: "#d9efed" },
  },

  palette: {
    type: "dark", // Set the desired palette type (light or dark)
    primary: {
      main: "#009688", // Set your desired primary color
    },

    secondary: { main: "#d9efed" },
  },

  typography: {
    fontFamily: "Noto Naskh Arabic, sans-serif",
    fontSize: 16,
  },

  components: {
    MuiIcon: {
      defaultProps: {
        color: "primary",
      },
    },
    MuiDataGrid: {
      defaultProps: {
        localeText: {
          noRowsLabel: "لا توجد بيانات", // Custom "No rows" text in Arabic
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          backgroundColor: "#fff",
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          backgroundColor: "#fff",
        }),
      },
    },
  },
});

export default theme;
