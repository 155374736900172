import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CustomDatePicker from "../components/CustomDatePicker";
import axiosNT from "../redux/axiosInstances/axiosNoToken";
import { addAlertMessage } from "../redux/actions/systemActions";
import { useDispatch } from "react-redux";
import AlertMessage from "../components/AlertMessage";

const RegistrationPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [healthy, setHealthy] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [birthDate, setBirthDate] = useState(Date.now);
  const [address, setAddress] = useState("");
  const [note, setNote] = useState("");

  const [loading, setLoading] = useState(false);

  const registrate = async (e) => {
    e.preventDefault();

    // Parse the birth date string into a Date object
    const dateObj = new Date(birthDate);

    // Extract the year, month, and day components
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");

    // Format the date in your desired format
    const formattedDate = `${year}-${month}-${day}`;
    const data = {
      name: name,
      phone: phone,
      birth_date: formattedDate,
      address: address,
      note: "",
    };

    try {
      const { response } = await axiosNT.post("registration", data);
      dispatch(addAlertMessage("تم تسجيلك بنجاح"));
      navigate("/");
    } catch (error) {
      dispatch(addAlertMessage(error.response.data.detail, "error"));
    }
  };

  return (
    <div className="registration-page">
      <div style={{ width: "100%" }}>
        <AlertMessage />
      </div>
      <form onSubmit={registrate}>
        <div className="input-container">
          <Grid container spacing={2}>
            <Grid item sm={12} md={6} style={{ width: "100%" }}>
              <TextField
                fullWidth
                required
                autoComplete="off"
                label="الإسم الثلاثي"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item sm={12} md={6} style={{ width: "100%" }}>
              <CustomDatePicker
                label={"تاريخ الولادة"}
                style={{ width: "100%" }}
                containerStyle={{ padding: "5px", width: "100%" }}
                value={birthDate}
                setValue={setBirthDate}
              />
            </Grid>
            <Grid item sm={12} md={6} style={{ width: "100%" }}>
              <TextField
                fullWidth
                required
                autoComplete="off"
                label="رقم الهاتف"
                placeholder="09x-xxxxxxx"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Grid>
            <Grid item sm={12} md={6} style={{ width: "100%" }}>
              <TextField
                fullWidth
                autoComplete="off"
                label="العنوان"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Grid>
            <Grid item sm={12} md={12} style={{ width: "100%" }}>
              <div className="previous-registrations">
                <FormControlLabel
                  control={<Checkbox defaultChecked />}
                  label="شاركت في المارثون الأول سنة 2016"
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="شاركت في المارثون الثاني سنة 2017"
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="شاركت في المارثون الثالث سنة 2018"
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="شاركت في المارثون الرابع سنة 2019"
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="شاركت في المارثون الخامس سنة 2022"
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="شاركت في المارثون السادس سنة 2023"
                />
              </div>
            </Grid>
          </Grid>

          <FormControlLabel
            className="confirm-health"
            control={
              <Checkbox
                checked={healthy}
                onChange={(e) => setHealthy(e.target.checked)}
              />
            }
            label="الحمد لله، اتمتع بصحة جيدة وليس لدي أي مشاكل صحية تمنعني من المشاركة في السباق"
          />
        </div>
        <Grid container>
          <Grid item sm={12} md={4} style={{ width: "100%" }}>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              disabled={!healthy}
            >
              إرسال طلب إشتراك
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default RegistrationPage;
