import React from "react";
import Cookies from "js-cookie";
import { AppBar, Toolbar, IconButton, MenuItem, Menu } from "@mui/material";
import { useSelector } from "react-redux";

import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { useNavigate } from "react-router-dom";

const Nav = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const { loginInfo } = useSelector((state) => state.userLogin);

  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    Cookies.remove("userInfo");
    window.location.reload();
  };

  return (
    <nav>
      {/* <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{ mr: 2 }}
        onClick={() => setOpen(!open)}
      >
        <MenuIcon />
      </IconButton> */}
    </nav>
  );
};

export default Nav;
