import * as consts from "../constants/systemConstants";

export const addAlertMessage =
  (text, type = "info") =>
  (dispatch) => {
    dispatch({
      type: consts.ADD_ALERT_MESSAGE,
      payload: { type: type, text: text },
    });
    window.scrollTo(0, 0);
  };

export const removeAlertMessage = (messageId) => (dispatch) => {
  dispatch({ type: consts.REMOVE_ALERT_MESSAGE, payload: messageId });
};
