import MainContainer from "../containers/MainContainer";
import DashboardPage from "../pages/DashboardPage";
import LoginPage from "../pages/LoginPage";
import MainPage from "../pages/MainPage";
import RegistrationPage from "../pages/RegistrationPage";

const mainRoutes = [
  {
    path: "/login",
    element: <LoginPage />,
    isPrivate: false,
    mustLogout: true,
  },
  {
    path: "/",
    element: (
      <MainContainer>
        <MainPage />
      </MainContainer>
    ),
    isPrivate: false,
    mustLogout: false,
  },
  {
    path: "/dashboard",
    element: (
      <MainContainer>
        <DashboardPage />
      </MainContainer>
    ),
    isPrivate: false,
    mustLogout: false,
  },
  {
    path: "/registration",
    element: <RegistrationPage />,
    isPrivate: false,
    mustLogout: false,
  },
];

export default mainRoutes;
