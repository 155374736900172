import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import { login } from "../redux/actions/userActions";
import AlertMessage from "../components/AlertMessage";

const LoginPage = () => {
  const dispatch = useDispatch();

  const { loadingLogin } = useSelector((state) => state.userLogin);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login(username, password));
  };

  return (
    <>
      <AlertMessage />
      <div className="login-page">
        <form onSubmit={handleSubmit} className="login-container">
          <img src={`${process.env.PUBLIC_URL}/images/logo.svg`} />
          <TextField
            required
            autoComplete="off"
            className="login-input"
            type="text"
            label="اسم المستخدم أو رقم الهاتف"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            required
            className="login-input"
            type="password"
            label="كلمة المرور"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormControlLabel
            style={{ alignSelf: "flex-start", marginRight: "30px" }}
            control={<Checkbox defaultChecked />}
            label="تذكرني"
          />

          <Button
            variant="contained"
            type="submit"
            className="login-button"
            endIcon={loadingLogin && <CircularProgress />}
          >
            تسجيل الدخول
          </Button>
          <p className="create-account-link">
            ليس لديك حساب؟
            <Link to={"/signup"}>
              <span> إنشاء حساب</span>
            </Link>
          </p>
        </form>
        <br />
        <br />
        <a
          style={{
            fontSize: "0.72rem",
            margin: "auto auto 15px auto",
            color: "var(--primary-color)",
          }}
          href="https://fvtion.com/"
          target="_blank"
        >
          © جميع الحقوق محفوظة لشركة طلبك - تصميم شركة رؤية المستقبل 2023 ©
        </a>
        <a href=""></a>
      </div>
    </>
  );
};

export default LoginPage;
